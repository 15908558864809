@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
    .socials {
      @apply w-[35px] h-[35px] rounded-[100%] flex justify-center items-center border border-[#1B1C19] hover:border-none transition-all duration-500 ease-in-out;
    }
}
body{
  overflow-x: hidden;
}
.generalbody{
  max-width: 1800px;
  margin: auto;
  overflow-x: hidden;
}

