*{
    font-family: 'Work Sans';
}

#title{
    font-family: 'Luckiest Guy', cursive;
}
.gradient{    
    background: linear-gradient(180deg, rgba(168, 202, 85, 0.15) 0%, rgba(168, 202, 85, 0) 100%), #fff;

}