.title{
    font-family: 'Luckiest Guy';
    word-spacing: 8px;
    
     
}
.text{
    font-family: 'Work Sans';
}
/* .gradient{    
    background: linear-gradient(180deg, #f9c0c2 0%, rgba(168, 202, 85, 0) 100%), #fff;

} */
.last{
    background: rgba(30,105,255,.08);;
}
.socials{
    font-size: 1.1rem;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
}
.socials:hover{
 background-color: #fc4442; 
 color: white;  
}