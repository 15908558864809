#data{
    font-family: 'Luckiest Guy';
}
.downtext{
    font-family: "Work Sans",sans-serif;
    
}
/* projects  */
.title{
    font-family: 'Luckiest Guy';
    word-spacing: 8px;
     
}
.boxes{
   
     box-shadow: 0px 4px 50px rgba(0,0,0,.12);
    
     font-family: "Work Sans",sans-serif;
   

}
.custom-dot-list-style {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 4rem;
    display: flex;
    list-style-type: none;
    padding: 0;
    justify-content: center;
    align-items: center;
  }
  
  .custom-dot-list-style li {
    margin: 0 0.5rem;
  }
  
  .custom-dot {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    transition: background-color 0.3s;
  }
  
  .custom-dot.active {
    background-color: white;
  }
@media (max-width:1020px){
.active{
    display: none;
}

  li.active {
    background-color: black;
    width: 8px;
    height: 3px;
  }

}
